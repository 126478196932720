// ValentineScreen.js
import React from "react";

const ValentineScreen = ({ accepted, onYes, onNo }) => {
  return (
    <div className="valentine-screen">
      <p className="valentine-text">Will you be my Valentine?</p>
      {accepted ? (
        <div class="accept-text">YAY! I'll see you on the 14th then ❤️</div>
      ) : (
        <>
          <div class="love">
            <button
              type="button"
              id="yes-button"
              class="buttons"
              onClick={onYes}
            >
              Yes
            </button>
            <div id="heart1" class="hearts"></div>
            <div id="heart2" class="hearts"></div>
            <div id="heart3" class="hearts"></div>
            <div id="heart4" class="hearts"></div>
            <div id="heart5" class="hearts"></div>
          </div>
          <button type="button" id="no-button" class="buttons" onClick={onNo}>
            No
          </button>
        </>
      )}
    </div>
  );
};

export default ValentineScreen;
