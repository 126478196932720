import React, { useEffect } from "react";
import Shrine from "./photos/shrine.png";

const AngryAnimation = ({ onAnimationComplete }) => {
  useEffect(() => {
    // Add any animations or effects here

    // Example: After 3 seconds, call the onAnimationComplete callback
    const timeout = setTimeout(() => {
      onAnimationComplete();
    }, 3000);

    return () => clearTimeout(timeout);
  }, [onAnimationComplete]);

  return (
    <div className="angry-animation">
      <img className="angry-image" src={Shrine} alt="Angry Image" />
    </div>
  );
};

export default AngryAnimation;
