// TypingAnimation.js
import React, { useState, useEffect } from "react";
import BestFriend from "./photos/bestfriend.png";
import AmandaShui from "./photos/amandashui.png";
import GymBuddy from "./photos/gymbuddy.png";
import HoneyBooBoo from "./photos/honeybooboo.png";
import MooMooMands from "./photos/poopoopands.png";

const TypingAnimation = ({ onAnimationComplete }) => {
  const messages = [
    "To Amanda Shui...",
    "To my Gym Buddy...",
    "To my Honey Boo Boo...",
    "To my Moo Moo Mands...",
    "To my Best Friend...",
  ];
  const photos = [
    <img src={AmandaShui} />,
    <img src={GymBuddy} />,
    <img src={HoneyBooBoo} />,
    <img src={MooMooMands} />,
    <img src={BestFriend} />,
  ];
  const [index, setIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [currentPhoto, setCurrentPhoto] = useState(null);
  //   const [currentText, setCurrentText] = useState(messages[0]);

  useEffect(() => {
    let timeout;
    if (index === 0) {
      timeout = setTimeout(() => {
        setCurrentText(messages[index]);
        setCurrentPhoto(photos[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, 2000);
    } else {
      timeout = setTimeout(() => {
        if (index === messages.length) {
          onAnimationComplete();
          return;
        }
        setCurrentText(messages[index]);
        setCurrentPhoto(photos[index]);
        console.log(index, currentText, "huh");
        setIndex((prevIndex) => prevIndex + 1);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [index]);

  console.log(index, currentText, "@@@@@");

  return (
    <div className="typewriter-container">
      <div className="typewriter">
        <p key={currentText}>{currentText}</p>
      </div>
      <div className="image-container">{currentPhoto}</div>
    </div>
  );
};

export default TypingAnimation;
