// App.js
import React, { useState } from "react";
import TypingAnimation from "./TypingAnimation.jsx";
import ValentineScreen from "./ValentineScreen.jsx";
import AngryAnimation from "./AngryAnimation.jsx";
import ConfettiAnimation from "./Confetti.jsx";

const App = () => {
  const [showValentineScreen, setShowValentineScreen] = useState(false);
  const [showAngryAnimation, setShowAngryAnimation] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const handleAnimationComplete = () => {
    setShowValentineScreen(true);
  };

  const handleYes = () => {
    setShowConfetti(true);
  };

  const handleNo = () => {
    setShowAngryAnimation(true);
  };

  const handleAngryAnimationComplete = () => {
    setShowAngryAnimation(false);
  };

  const handleConfettiAnimationComplete = () => {
    setShowConfetti(false);
  };

  return (
    <div className="body">
      <ConfettiAnimation
        onAnimationComplete={handleConfettiAnimationComplete}
        hidden={!showConfetti}
      />
      {showAngryAnimation && (
        <AngryAnimation onAnimationComplete={handleAngryAnimationComplete} />
      )}
      {!showValentineScreen ? (
        <>
          <TypingAnimation onAnimationComplete={handleAnimationComplete} />
        </>
      ) : (
        <ValentineScreen
          accepted={showConfetti}
          onYes={handleYes}
          onNo={handleNo}
        />
      )}
    </div>
  );
};

export default App;
