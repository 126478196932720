import React, { useEffect } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

const ConfettiAnimation = ({ hidden, onAnimationComplete }) => {
  const { width, height } = useWindowSize();

  useEffect(() => {
    // Add any animations or effects here

    // Example: After 3 seconds, call the onAnimationComplete callback
    const timeout = setTimeout(() => {
      onAnimationComplete();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [onAnimationComplete]);

  return (
    <div style={{ display: hidden ? "none" : "block" }}>
      <Confetti width={width} height={height} />
    </div>
  );
};

export default ConfettiAnimation;
